import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { getWepaBookings_getWepaBookings_bookings } from "lib/api/queries/graphql/getWepaBookings";
import { StorageConfigStructure } from "components/client/company/components/GetStorageConfig";
import BrokerageAccountList from "client/components/BrokerageAccountList/BrokerageAccountList";
import ConfirmAccountingYear from "client/components/ConfirmAccountingYear/ConfirmAccountingYear";
import DocumentSection from "client/components/DocumentSection/DocumentSection";
import React from "react";
import { useHistory } from "react-router-dom";
import { FeatureFlags, UserRole } from "../../../../global-query-types";
import { FeatureFlag } from "../../../../sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";
import WepaBookingsCard from "../../../../sharedComponents/CompanyFields/CompanyWepaBookingsCard/WepaBookingsCard";
import CompanyAccountingFrameworkCard from "../../../../sharedComponents/CompanyFields/CompanyAccountingFrameworkCard/CompanyAccountingFrameworkCard";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "../../../../uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "../../../../uiLibrary/designSystem/RideButton/RideButton";
import NavigationService from "../../../../lib/services/NavigationService/NavigationService";
import { QuestionIcon } from "../../../../sharedComponents/icons/DesignSystemIcons/QuestionIcon";
import { RideSectionCard } from "../../../../sharedComponents/RideSectionCard/RideSectionCard";
import { DisabledFeatureFlag } from "../../../../sharedComponents/FeatureFlags/DisabledFeatureFlag/DisabledFeatureFlag";
import { ArrowRight } from "../../../../sharedComponents/icons/DesignSystemIcons/ArrowRight";
import {
  ButtonSize,
  ButtonVariant,
  RideButtonDeprecated
} from "../../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";

// Get the base URL from environment variable with a default fallback
const NEXT_FRONTEND_BASE_URL =
  process.env.REACT_APP_NEXT_FRONTEND_BASE_URL || "https://ride.capital";

interface WepaGeneralDataProps {
  gmbh: GmbHDetails;
  bookings: getWepaBookings_getWepaBookings_bookings[];
  onConfirmBooking: (bookingId: number) => void;
  wepaStructure: StorageConfigStructure[];
}

// Broker Connections CTA component shown when the feature flag is enabled
const NewBrokerConnectionsCTA = ({ t }: { t: any }) => {
  const externalUrl = `${NEXT_FRONTEND_BASE_URL}/customer/wepa/all/connections`;

  return (
    <RideSectionCard
      data-testid="broker-connections-cta"
      title={t("client:company.brokerage-account-list.title")}
      className="my-5">
      <div className="broker-connections-cta">
        <div className="broker-connections-cta__content">
          <Typography category={"Headline"} size={300} weight={"Heavy"} className="mb-3">
            {t("client:company.broker-connections.new-system-available")}
          </Typography>
          <Typography category={"Paragraph"} size={200} weight={"Light"} className="mb-4">
            {t("client:company.broker-connections.new-system-description")}
          </Typography>
          <RideButtonDeprecated
            data-testid="broker-connections-cta-button"
            openExternalUrl
            href={externalUrl}
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Medium}
            hasIcon>
            {t("client:company.broker-connections.manage-broker-connections")}
            <ArrowRight size={20} />
          </RideButtonDeprecated>
        </div>
      </div>
    </RideSectionCard>
  );
};

const WepaGeneralData = ({
  t,
  gmbh,
  bookings,
  onConfirmBooking,
  wepaStructure
}: WepaGeneralDataProps & WithTranslation) => {
  const history = useHistory();
  const handleEditBrokerageAccount = (id: string) => {
    history.push(`/client/entities/${gmbh.id}/securities-booking/edit/${id}`);
  };

  const handleAddBrokerageAccount = () => {
    history.push(`/client/entities/${gmbh.id}/securities-booking/new`);
  };

  let isWepaDirectClient =
    gmbh.orders.find((o) => o.productType === "WepaDirect" && o.status === "Completed") !==
    undefined;

  const getWepaBookingsInformation = () => {
    const isWepaDirect = gmbh.orders.find(
      (o) => o.productType === "WepaDirect" && o.status === "Completed"
    );
    const isASCustomer = gmbh.adminServiceStatus === "ACTIVE";
    const isWepaOldContract = !isWepaDirect && !isASCustomer;

    let suffix = "";

    if (isASCustomer) {
      suffix = "for-admin-service-client";
    }
    if (isWepaOldContract) {
      suffix = "for-wepa-standalone-client";
    }
    if (isWepaDirect) {
      suffix = "for-wepa-direct-client";
    }

    return (
      <div className={"wepa-general-data__bookings-header"}>
        <Typography
          data-testid={"client-booking-table-header"}
          category={"Headline"}
          size={300}
          weight={"Heavy"}>
          {t("generic:wepa-bookings.header")}
        </Typography>
        <Typography
          data-testid={"client-booking-table-description"}
          category={"Paragraph"}
          size={200}
          weight={"Light"}>
          {t(`generic:wepa-bookings.description.${suffix}`)}
        </Typography>
        {isWepaDirect && (
          <RideButton
            data-testid={"how-to-use-bookings-link"}
            label={t("generic:wepa-bookings.how-to-use-bookings-label")}
            variant={RideButtonVariant.GHOST}
            size={RideButtonSize.SMALL}
            leftIcon={<QuestionIcon size={20} />}
            onClick={() =>
              NavigationService.instance?.openLink(
                "https://www.ride.capital/faq/wepa-buchungsstapel-download"
              )
            }
          />
        )}
      </div>
    );
  };

  return (
    <div data-testid="wepa-service-general-data">
      {/* Show the new CTA when the feature flag is enabled */}
      <FeatureFlag name={FeatureFlags.WepaNextBrokerConnections}>
        <NewBrokerConnectionsCTA t={t} />
      </FeatureFlag>

      {/* Show the original BrokerageAccountList when the feature flag is disabled */}
      <DisabledFeatureFlag name={FeatureFlags.WepaNextBrokerConnections}>
        <BrokerageAccountList
          list={gmbh.brokerageAccounts ?? []}
          onEdit={handleEditBrokerageAccount}
          onAdd={handleAddBrokerageAccount}
        />
      </DisabledFeatureFlag>

      <ConfirmAccountingYear
        companyId={gmbh.id}
        bookings={bookings}
        onConfirmBooking={onConfirmBooking}
      />
      {gmbh.hasWepaStorage && (
        <>
          <DocumentSection
            testid="wepa"
            storageConfig={wepaStructure}
            gmbh={gmbh}
            nodeList={gmbh.wepaNodeList}
          />
        </>
      )}
      <FeatureFlag name={FeatureFlags.WepaBookingsDownloadForClient}>
        {getWepaBookingsInformation()}
      </FeatureFlag>
      <FeatureFlag name={FeatureFlags.WepaDirect}>
        {isWepaDirectClient && (
          <CompanyAccountingFrameworkCard
            t={t}
            gmbh={gmbh}
            handleEditField={(field) => () => history.push(`/client/entities/${gmbh.id}/${field}`)}
          />
        )}
      </FeatureFlag>
      <FeatureFlag name={FeatureFlags.WepaBookingsDownloadForClient}>
        <div className={"wepa-general-data__bookings-table"}>
          <WepaBookingsCard gmbh={gmbh} asUser={UserRole.Client} />
        </div>
      </FeatureFlag>
    </div>
  );
};

export default withTranslationReady(["generic"])(WepaGeneralData);
